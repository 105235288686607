.navbar-toggler:focus {
     box-shadow: none !important;
}

.navbar-toggler {
    border: none !important;
}

.navbar-brand {
    font-size: xx-large !important;
}

.me-auto {

    margin-right: auto!important;
    display: flex;
    align-items: flex-end;
}

.bar{
    background: white;
    width: 100%;
    height: 5.25rem ;
}